import type { FlowEditorSDK, TFunction } from '@wix/yoshi-flow-editor';
import { PagesPanelTabType, type ComponentRef, type PageRef } from '@wix/platform-editor-sdk';
import { APP_DEF_ID, DEFAULT_APP_WIDTH, PAGE_DATA } from 'root/consts';
import type { ComponentStructure, PageData } from 'root/clientTypes';
import { createSectionDefinition } from './createSectionDef';

const addWidgetAsPage = async ({
  editorSDK,
  appDefId,
  pageData,
  pageTitle,
  shouldAddMenuItem = false,
  shouldNavigateToPage,
  tpaAppId,
  isResponsive,
}: {
  editorSDK: FlowEditorSDK;
  appDefId: string;
  pageData: PageData;
  pageTitle: string;
  shouldAddMenuItem?: boolean;
  shouldNavigateToPage: boolean;
  tpaAppId: number;
  isResponsive: boolean;
}): Promise<PageRef> => {
  const { pageId, widgetId, pageUriSEO } = pageData;

  const tpaWidget: ComponentStructure = {
    componentType: 'wysiwyg.viewer.components.tpapps.TPASection',
    data: {
      applicationId: `${tpaAppId}`,
      widgetId,
      appDefinitionId: appDefId,
      type: 'TPA',
    },
    skin: 'wysiwyg.viewer.skins.TPASectionSkin',
    layout: {
      height: 0,
      width: DEFAULT_APP_WIDTH,
      x: 0,
      y: 0,
    },
    layouts: isResponsive
      ? {
          type: 'SingleLayoutData',
          componentLayout: {
            type: 'ComponentLayout',
            width: {
              type: 'auto',
            },
            height: {
              type: 'auto',
            },
          },
          itemLayout: {
            id: '',
            alignSelf: 'stretch',
            margins: {
              top: {
                type: 'px',
                value: 0,
              },
              right: {
                type: 'px',
                value: 0,
              },
              bottom: {
                type: 'px',
                value: 0,
              },
              left: {
                type: 'px',
                value: 0,
              },
            },
            gridArea: {
              rowStart: 1,
              rowEnd: 2,
              columnStart: 1,
              columnEnd: 2,
            },
            justifySelf: 'stretch',
            type: 'GridItemLayout',
          },
        }
      : undefined,
  };

  const pageRefPromise = await editorSDK.document.transactions.runAndWaitForApproval('token', async () => {
    const pageRef = await editorSDK.document.pages.add('', {
      title: pageTitle,
      definition: {
        id: '',
        type: 'Page',
        componentType: 'mobile.core.components.Page',
        components: [
          isResponsive
            ? createSectionDefinition([tpaWidget])
            : {
                anchor: {
                  id: '',
                  type: 'AnchorInfo',
                  name: 'Menus',
                },
                componentType: 'wysiwyg.viewer.components.ClassicSection',
                components: [tpaWidget],
                design: {
                  type: 'MediaContainerDesignData',
                  background: {
                    type: 'BackgroundMedia',
                  },
                },
              },
        ],
        data: {
          appDefinitionId: appDefId,
          managingAppDefId: appDefId,
          tpaPageId: pageId,
          pageUriSEO,
          tpaApplicationId: tpaAppId,
        },
      },
      shouldAddMenuItem,
      shouldNavigateToPage,
    });

    return pageRef;
  });

  return pageRefPromise;
};

export const setPagesState = async ({ editorSDK }: { editorSDK: FlowEditorSDK }) => {
  const applicationPages = await editorSDK.document.pages.data.getAll('');

  const dupPages = applicationPages.filter((page) => page.tpaPageId === PAGE_DATA.pageId);

  editorSDK.document.pages.setState('', {
    state: {
      [PAGE_DATA.pageId]: dupPages.map((page) => ({ id: page.id })) as PageRef[],
    },
  });
};

export const createAppPage = async ({
  editorSDK,
  appDefId,
  pageData,
  pageTitle,
  shouldAddMenuItem = false,
  shouldNavigateToPage,
  tpaAppId,
  isResponsive,
}: {
  editorSDK: FlowEditorSDK;
  appDefId: string;
  pageData: PageData;
  pageTitle: string;
  shouldAddMenuItem?: boolean;
  shouldNavigateToPage: boolean;
  tpaAppId: number;
  isResponsive: boolean;
}): Promise<{ pageRef: PageRef; widgetRef?: ComponentRef }> => {
  const ppPageRef = await addWidgetAsPage({
    editorSDK,
    appDefId,
    pageData,
    pageTitle,
    shouldAddMenuItem,
    shouldNavigateToPage,
    tpaAppId,
    isResponsive,
  });

  await setPagesState({ editorSDK });

  return { pageRef: ppPageRef };
};

export const createMenuPage = async ({
  editorSDK,
  t,
  tpaAppId,
  isResponsive,
}: {
  editorSDK: FlowEditorSDK;
  t: TFunction;
  tpaAppId: number;
  isResponsive: boolean;
}) => {
  const progressBarTexts = {
    title: t('add-page.progress-bar.title'),
    inProgress: t('add-page.progress-bar.in-progress'),
    completed: t('add-page.progress-bar.completed'),
  };

  await editorSDK?.editor.openProgressBar('', {
    title: progressBarTexts.title,
    stepTitle: progressBarTexts.inProgress,
    totalSteps: 1,
  });

  const pageTitle = t(PAGE_DATA.title);
  const { pageRef } = await createAppPage({
    editorSDK,
    appDefId: APP_DEF_ID,
    pageData: PAGE_DATA,
    pageTitle,
    shouldAddMenuItem: true,
    shouldNavigateToPage: true,
    tpaAppId,
    isResponsive,
  });

  await editorSDK?.editor.updateProgressBar('', {
    currentStep: 1,
    stepTitle: progressBarTexts.completed,
  });
  await editorSDK?.editor.closeProgressBar('', {});

  await editorSDK?.editor.openPagesPanel('', {
    pageRef,
    renameEnabled: true,
    initialSettingsTabType: PagesPanelTabType.PageInfo,
  });
};
